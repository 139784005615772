import { Container, ContainerTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

type ContainerProps = {
  children: React.ReactElement
} & ContainerTypeMap['props']

function AppContainer({ children, ...otherProps }: ContainerProps) {
  const { sx, ...props } = otherProps

  return (
    <Container
      sx={{
        ...{
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Container>
  )
}

export default AppContainer
