import { Box } from '@mui/material'
import { Footer, Header } from '..'
import AppContainer from '../commonComponents/AppContainer/AppContainer'

type HeaderProps = {
  children: React.ReactElement
}

function Layout({ children }: HeaderProps) {
  return (
    <>
      <Box className="main-header">
        <AppContainer disableGutters maxWidth="xl">
          <Header />
        </AppContainer>
      </Box>
      <AppContainer
        sx={{
          minHeight: '100vh',
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
        disableGutters
        maxWidth="xl"
      >
        {children}
      </AppContainer>
      <Box className="main-footer">
        <AppContainer disableGutters maxWidth="xl">
          <Footer />
        </AppContainer>
      </Box>
    </>
  )
}

export default Layout
