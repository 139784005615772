import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ExampleApi from "../../api/example/exampleApi";
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { Slider } from '../../components'
import { SLIDER_CLASS } from '../../utils/const'
import { markdownServerError } from "../MainPage/const";

const SOURCE_TYPE_REGEXP = /[a-zA-Z]/g;

function ExamplePage() {
  // const { pathname } = useLocation()
  const { id, source } = useParams<{ id: string; source: string }>();
  const [post, setPost] = useState<any>("");

  useEffect(() => {
    // const source = pathname.match(SOURCE_TYPE_REGEXP)?.join('')

    try {
      ExampleApi.getPost(source, id)
        .then((res: any): any => {
          setPost(res);
        })
        .catch((err: any) => {
          setPost(markdownServerError)
          // throw new Error("Network error", err);
        });
    } catch (e) {
      console.log(e);
    }
  }, [id, source]);

  const checkSliderContainer = (
    className: string | undefined,
    children: React.ReactNode & React.ReactNode[],
  ) => {
    if (className === SLIDER_CLASS) {
      return <Slider elements={children} />
    }

    return null
  }

  return (
    <Box>
      {/* <Typography textAlign="center">{post}</Typography> */}
      <Box display="flex" flexDirection="column">
      <ReactMarkdown
        className="markdown-body"
        children={post}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          div({ className, children }) {
            return checkSliderContainer(className, children)
          },
        }}
      />
      </Box>
    </Box>
  );
}

export default ExamplePage;
