import { MainPage, ExamplePage } from '../pages'

export const routes = [
  {
    path: '/main',
    exact: false,
    component: MainPage,
  },
  {
    path: '/:source/:id',
    exact: true,
    component: ExamplePage,
  },
  // {
  //   path: '/advert/:id',
  //   exact: true,
  //   component: ExamplePage,
  // },
]
